import React from 'react';
import { Layout } from '@common';
import { Section, Container } from '@components/global';
import { Content, Paragraph, OfficerGrid } from './style';
import { OfficerCard } from '@common';
import { useImage } from '@utils';

const TeamTemplate = ({ pageContext: { name, desc, dosen, officer } }) => {
  const dosenImgUrl = useImage(name);
  return (
    // <Layout page={name} includeHeading={false}>
    //   <Section style={{ paddingBottom: '48px' }}>
    //     <Container>
    //       <h2>SRE {name}</h2>
    //       <Content>
    //         <Paragraph style={{ textAlign: 'center' }}>{desc}</Paragraph>
    //       </Content>
    //     </Container>
    //   </Section>
    //   <Container>
    //     <Content>
    //       <h2>Staff SRE {name}</h2>
    //       <OfficerGrid>
    //         {officer.map(datum => (
    //           <OfficerCard
    //             key={datum}
    //             title={datum}
    //             subtitle={datum.split('_')[1]}
    //             folderPath={`gs://sre-website-a43e8.appspot.com/teams/BoD/${name}/`}
    //             elevated={true}
    //           />
    //         ))}
    //       </OfficerGrid>
    //     </Content>
    //   </Container>
    //   <Section>
    //     <Container>
    //       <Content>
    //         <h2>Dosen Kami</h2>
    //         <div
    //           style={{
    //             maxWidth: '500px',
    //           }}
    //         >
    //           <OfficerCard
    //             imgUrl={dosenImgUrl}
    //             title={dosen.name}
    //             folderPath="gs://sre-website-a43e8.appspot.com/teams/dosen/"
    //             subtitle={dosen.role}
    //             elevated={true}
    //           />
    //         </div>
    //       </Content>
    //     </Container>
    //   </Section>
    // </Layout>
    <Layout page="Coming Soon">
      {/* <div style={{textAlign: 'center', margin: '120px auto'}}>
        <h3>Belum ada publikasi</h3>
      </div> */}
    </Layout>
  );
};

export default TeamTemplate;
